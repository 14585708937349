import React, {useEffect, useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch, withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {cloneDeep} from 'lodash';
import DeliverablesGuideSection from '../baseline/DeliverablesGuideSection';
import PhasesSection from './PhasesSection';
import {ContentContainer, InitialPlanPageWrapper, StickySection} from './InitialPlan.styled';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, MODULE_TYPES} from '../../constants';
import {getInitialOptions, handleChangedOptions, theEyeToColumns} from '../../the_eye_util';
import {
	getGroupedFinancialNumbersByPhaseAndBaselineId,
	setShouldCollapseAll,
	theEyeOptions,
	toggleAllPhases,
} from './InitialPlanUtil';
import {adjustBaseline, createNewPhase} from './InitialPlanLogic';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {Settings} from './Settings';
import EmptyState from '../../forecast-app/shared/components/empty-states/empty_state';
import {withSocketHandling} from '../../socket/withSocketHandling';
import {getSocketConfig} from './InitialPlanSocket';
import HeaderSection from './HeaderSection';
import {TopHeaderBar} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import PhaseFormatter from '../../forecast-app/shared/util/export-formatters/PhaseFormatter';
import {useDataExport} from '../../forecast-app/shared/hooks/useDataExport';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {useTrackPage} from '../../tracking/amplitude/hooks/useTrackPage';
import ProjectUtil from '../../forecast-app/shared/util/project_util';
import ProjectHeader from '../../forecast-app/project-tab/projects/shared/ProjectHeader';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import BaselinePDF from './pdf/BaselinePDF';
import {formatPhases, formatProject} from './pdf/BaselinePDFFormatter';
import {format} from 'date-fns';
import {projectUrl} from '../../directApi';
import {getProjectIndicatorString} from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import {DeliverablesPhasesSection} from '../baseline/DeliverablesPhasesSection';

const SpecialPhase = {
	NO_PHASE: 0,
	PROJECT_TIME_REG: -1,
	OFFSET: -2,
};

export const InitialPlanPage = ({intl, viewer, retry, setSocketConfig, buyNowTime}) => {
	const {totalFinancialNumbers, financialNumbersMap: groupedFinancialNumbers} =
		getGroupedFinancialNumbersByPhaseAndBaselineId(viewer.project.groupedFinancialNumbers);
	const projectDoneOrHalted = viewer.project.status === 'DONE' || viewer.project.status === 'HALTED';
	const isFixedPrice =
		viewer.project.budgetType === BUDGET_TYPE.FIXED_PRICE || viewer.project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	const useFixedPriceForBaselineRevenue = viewer.project.useFixedPriceForBaselineRevenue;
	const SETTINGS_PATH = 'settings';
	const {path} = useRouteMatch();
	const location = useLocation();
	const history = useHistory();
	const localStorageTheEyeName = 'the-eye-baseline';
	const currencySymbol = Util.GetCurrencySymbol(
		viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency
	);
	const [theEyeOpts, setTheEyeOptions] = useState(
		getInitialOptions(
			theEyeOptions(useFixedPriceForBaselineRevenue, isFixedPrice, !ProjectUtil.projectTracksRevenue(viewer.project)),
			localStorageTheEyeName
		)
	);
	const enabledColumns = useMemo(() => theEyeToColumns(theEyeOpts), [theEyeOpts]);
	const [collapseAll, setCollapseAll] = useState(true);

	const [phaseSortOrder, setPhaseSortOrder] = useState(
		Util.sortBaselinePhase(cloneDeep(viewer.project.phases.edges)).map(phase => phase.node.id)
	);

	const phaseFormatter = new PhaseFormatter(intl);
	const exportData = useDataExport(phaseFormatter, undefined, undefined, () => `${viewer.project.name}_baseline`);

	useTrackPage('Baseline');

	useEffect(() => {
		const name = viewer.project
			? viewer.project.name !== null && viewer.project.name !== ''
				? viewer.project.name
				: getProjectIndicatorString(viewer.project.companyProjectId, viewer.project.customProjectId)
			: null;
		document.title = 'Baseline - ' + name + ' - Forecast';
		tracking.trackPage('Baseline');

		const projectId = parseInt(atob(viewer.project.id).replace('ProjectType:', ''));
		setSocketConfig(getSocketConfig(projectId));
		setShouldCollapseAll(viewer.project.id, collapseAll, setCollapseAll);
	}, []);

	useEffect(() => {
		setTheEyeOptions(
			getInitialOptions(
				theEyeOptions(useFixedPriceForBaselineRevenue, isFixedPrice, !ProjectUtil.projectTracksRevenue(viewer.project)),
				localStorageTheEyeName
			)
		);
	}, [viewer.project.useFixedPriceForBaselineRevenue]);

	// Redirect if not a baseline project
	if (!Util.isBaselineProject(viewer.company.modules, viewer.project)) {
		return <Redirect to={projectUrl(viewer.project.companyProjectId, viewer.project.customProjectId) + '/workflow/'} />;
	}

	const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
		const optionOrder = theEyeOptions(
			useFixedPriceForBaselineRevenue,
			isFixedPrice,
			viewer.project.budgetType === BUDGET_TYPE.NON_BILLABLE
		).map(opt => opt.name);
		const sortedNewOptions = newOptions.sort((a, b) =>
			optionOrder.indexOf(a.name) > optionOrder.indexOf(b.name) ? 1 : -1
		);
		setTheEyeOptions(handleChangedOptions(sortedNewOptions, localStorageTheEyeName));
	};

	const shouldBlockBaseline = () => {
		return viewer.project.projectStartYear === null || viewer.project.projectEndYear === null;
	};

	const addProjectDates = () => {
		showModal({
			type: MODAL_TYPE.ADD_PROJECT_DATES,
			project: viewer.project,
			datesSetCallback: () => retry(),
		});
	};

	const handleToggleAllPhases = () => {
		toggleAllPhases(viewer.project.id, !collapseAll);
		setCollapseAll(!collapseAll);
	};

	const onPhaseCreate = res => {
		setPhaseSortOrder([res.createPhase.phase.node.id].concat(phaseSortOrder));
	};

	const getHeaderTitleContent = () => {
		const onboardingFlows = [
			{
				id: 'baseline-introduction',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681819180hSkd2591',
			},
		];

		return [
			{
				id: 'onboarding-component',
				type: TopHeaderBar.TYPE.ONBOARDING,
				title: intl.formatMessage({id: 'baseline.onboarding_title'}),
				options: onboardingFlows,
				helpCenterLink: 'https://support.forecast.app/hc/en-us/categories/4418778811281-Financial-Management',
				subLink:
					'https://support.forecast.app/hc/en-us/articles/4977167479185-Setting-and-Reviewing-Project-Baseline-Pro-and-Plus-only-',
			},
		];
	};

	const showAdjustBaselineModal = () => {
		showModal({
			type: MODAL_TYPE.BASELINE_ADJUST_MODAL,
			baselineTarget: viewer.project.baselineTarget,
			baselineTargetPrice: viewer.project.baselineTargetPrice,
			baselineAdjustPrice: viewer.project.baselineAdjustPrice,
			baselineAdjustPercentage: viewer.project.baselineAdjustPercentage,
			currency: viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency,
			currencySymbol,
			projectId: viewer.project.id,
			onSave: adjustBaseline,
		});
	};

	const exportProject = useMemo(() => {
		return formatProject({
			project: viewer.project,
			totalFinancialNumbers,
			currencySymbol,
			intl,
		});
	}, [viewer.project, totalFinancialNumbers, currencySymbol, intl]);

	const exportPhases = useMemo(() => {
		return formatPhases({
			phases: viewer.project.phases.edges,
			groupedFinancialNumbers,
			phaseSortOrder,
			currencySymbol,
			intl,
		});
	}, [viewer.project.phases.edges, groupedFinancialNumbers, phaseSortOrder, currencySymbol, intl]);

	const exportDate = useMemo(() => {
		return format(new Date(), 'PP');
	}, []);

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		if (hasPermission(PERMISSION_TYPE.PHASE_CREATE)) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: intl.formatMessage({id: 'project_scoping.new-scope-group'}),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				callback: () => createNewPhase(viewer.project.id, intl.formatMessage, onPhaseCreate),
				userpilot: 'new-milestone-button',
				dataCy: 'baseline-new-phase-button',
				disabled: shouldBlockBaseline(),
			});
		}
		if (
			viewer.project.budgetType === BUDGET_TYPE.FIXED_PRICE &&
			hasFeatureFlag('baseline_adjustment', viewer.availableFeatureFlags)
		) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: intl.formatMessage({id: 'baseline.adjust_baseline_total'}),
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.LIGHTGREY,
				callback: showAdjustBaselineModal,
				userpilot: 'new-milestone-button',
				dataCy: 'baseline-new-phase-button',
				disabled: shouldBlockBaseline(),
			});
		}

		if (!shouldBlockBaseline()) {
			const settings = {
				type: ELEMENT_TYPE.BUTTON,
				text: intl.formatMessage({id: 'settings.title'}),
				callback: () => history.push(`${location.pathname}/${SETTINGS_PATH}`),
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.LIGHTGREY,
				userpilot: 'baseline-settings-button',
				dataCy: 'baseline-settins-button-container',
			};
			rightContent.push(settings);
		}

		rightContent.push({
			type: ELEMENT_TYPE.COLLAPSE,
			collapsed: collapseAll,
			toggleCollapse: handleToggleAllPhases,
		});

		const theEye = {
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOpts,
			onSelect: handleTheEyeOptionSelect,
			expandLeft: true,
			userpilot: 'eye-selector',
		};

		rightContent.push(theEye);

		if (!hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) {
			const csv = {
				type: ELEMENT_TYPE.CSV,
				callback: () =>
					exportData(
						theEyeOpts,
						Array.from(groupedFinancialNumbers.values())
							.filter(row => row.phaseId)
							.filter(row => ![SpecialPhase.PROJECT_TIME_REG, SpecialPhase.OFFSET].includes(row.phaseId)),
						{
							project: viewer.project,
							phases: viewer.project.phases.edges,
						}
					),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.LIGHTGREY,
				text: intl.formatMessage({id: 'common.export-csv'}),
				tooltipEnabled: true,
				tooltipProps: {
					autoPlace: true,
					grey: true,
					infoText: intl.formatMessage({id: 'common.export-csv'}),
				},
				disabled: shouldBlockBaseline(),
			};
			rightContent.push(csv);
		}

		const pdf = {
			type: ELEMENT_TYPE.PDF,
			document: (
				<BaselinePDF
					client={viewer.project.client}
					project={exportProject}
					phases={exportPhases}
					creatorName={viewer.fullName}
					companyName={viewer.company?.name}
					date={exportDate}
					currencySymbol={currencySymbol}
					intl={intl}
				/>
			),
			fileName: `${viewer.project.name}_baseline`,
			pageName: 'Baseline',
			cy: 'dowload-pdf',
			disabled: shouldBlockBaseline(),
		};
		rightContent.push(pdf);

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};
	const showDeliverables = viewer.project.useDeliverables;

	const hasBaselineItems = !!(
		viewer.project.phaseBaselineRoles.edges.length || viewer.project.phaseBaselineExpenses.edges.length
	);

	return (
		<Switch>
			<Route exact path={[path, `${path}/T:taskId(\\d+)`]}>
				<InitialPlanPageWrapper data-cy={'baseline-page'}>
					<ProjectHeader
						title={intl.formatMessage({id: 'project_section.baseline'})}
						titleContent={!showDeliverables ? getHeaderTitleContent() : undefined}
						buttons={!showDeliverables ? getHeader() : undefined}
						project={viewer.project}
						psProject={viewer.psProject}
					/>

					{shouldBlockBaseline() ? (
						<div style={{width: '100%'}} data-cy="baseline-empty-state">
							<EmptyState
								pageName={EmptyState.EMPTY_STATE.BASELINE_NO_DATES}
								callback={hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE) && addProjectDates}
							/>
						</div>
					) : showDeliverables ? (
						<ContentContainer>
							{hasBaselineItems && (
								<StickySection bottomSpacing>
									<HeaderSection
										project={viewer.project}
										currencySymbol={currencySymbol}
										totalFinancialNumbers={totalFinancialNumbers}
									/>
								</StickySection>
							)}
							{hasBaselineItems ? (
								<DeliverablesPhasesSection
									intl={intl}
									project={viewer.project}
									company={viewer.company}
									pageRetry={retry}
								/>
							) : (
								<DeliverablesGuideSection project={viewer.project} />
							)}
						</ContentContainer>
					) : (
						<ContentContainer>
							<StickySection bottomSpacing>
								<HeaderSection
									project={viewer.project}
									currencySymbol={currencySymbol}
									totalFinancialNumbers={totalFinancialNumbers}
								/>
							</StickySection>
							<PhasesSection
								expenseCategories={viewer.company.expenseCategories.edges}
								phaseSortOrder={phaseSortOrder}
								phases={viewer.project.phases.edges}
								intl={intl}
								project={viewer.project}
								roles={viewer.company.roles.edges}
								disabledRoleIds={
									viewer.project?.rateCard?.disabledRoles
										? viewer.project.rateCard.disabledRoles.map(role => role.id)
										: []
								}
								projectId={viewer.project.id}
								currency={viewer.company.currency}
								currencySymbol={currencySymbol}
								enabledColumns={enabledColumns}
								theEyeOptions={theEyeOpts}
								baselineTargetMinutes={viewer.project.baselineTargetMinutes}
								baselineTargetPrice={viewer.project.baselineTargetPrice}
								baselineWinChance={viewer.project.baselineWinChance}
								toggleCollapseAll={() => setShouldCollapseAll(viewer.project.id, collapseAll, setCollapseAll)}
								groupedFinancialNumbers={groupedFinancialNumbers}
								isFixedPrice={isFixedPrice}
								useFixedPriceForBaselineRevenue={useFixedPriceForBaselineRevenue}
							/>
						</ContentContainer>
					)}
				</InitialPlanPageWrapper>
			</Route>
			<Route exact path={[`${path}/${SETTINGS_PATH}`, `${path}/${SETTINGS_PATH}/T:taskId(\\d+)`]}>
				<Settings
					baselineMinutesPerDay={viewer.project.baselineEstimationMinutesPerDay}
					availableFeatureFlags={viewer.availableFeatureFlags}
					budgetType={viewer.project.budgetType}
					budget={viewer.project.budget}
					projectStage={viewer.project.status}
					project={viewer.project}
					company={viewer.company}
					currency={viewer.project.rateCard ? viewer.project.rateCard.currency : viewer.company.currency}
					baselineTargetMinutes={viewer.project.baselineTargetMinutes}
					baselineTargetPrice={viewer.project.baselineTargetPrice}
					baselineWinChance={viewer.project.baselineWinChance}
					salesforceOpportunity={viewer.project.salesforceOpportunity}
					selectedRateCardId={viewer.project.rateCard?.id}
					rateCards={viewer.company.rateCards.edges}
					taskCostCalculationType={viewer.project.taskCostCalculationType}
					projectDoneOrHalted={projectDoneOrHalted}
					unassignedTaskHourlyCost={viewer.project.unassignedTaskHourlyCost}
					baselineTarget={viewer.project.baselineTarget}
				/>
			</Route>
			<Route path="*">
				<Redirect to="/not-found" />
			</Route>
		</Switch>
	);
};

const InitialPlanPageQuery = graphql`
	query InitialPlanPage_Query($projectId: String, $hasDeliverablesFlag: Boolean!) {
		viewer {
			actualPersonId
			component(name: "project_initial_plan")
			project(id: $projectId) {
				id
			}
			...InitialPlanPage_viewer @arguments(projectId: $projectId, hasDeliverablesFlag: $hasDeliverablesFlag)
		}
	}
`;

export {InitialPlanPageQuery};

export default injectIntl(
	withRouter(
		withSocketHandling(
			createFragmentContainer(InitialPlanPage, {
				viewer: graphql`
					fragment InitialPlanPage_viewer on Viewer
					@argumentDefinitions(projectId: {type: "String"}, hasDeliverablesFlag: {type: "Boolean!"}) {
						id
						fullName
						email
						backendId
						actualPersonId
						availableFeatureFlags {
							key
						}
						company {
							name
							...RateCardDropdown_company
							currency
							roles(first: 1000000) @connection(key: "Company_roles", filters: []) {
								edges {
									...RoleDropdown_roles
									node {
										id
										name
									}
								}
							}
							rateCards {
								edges {
									...RateCardDropdown_rateCards
									node {
										id
										disabledRoles {
											id
										}
									}
								}
							}
							modules {
								moduleType
							}
							expenseCategories(first: 1000000) @connection(key: "Company_expenseCategories", filters: []) {
								edges {
									node {
										id
										name
										disabled
									}
								}
							}
							exchangeRates(first: 10000) {
								edges {
									node {
										id
										currency
										rate
									}
								}
							}
						}
						project(id: $projectId) {
							...ProjectHeader_project
							...SecondaryNavigation_project
							...HeaderSection_project
							useBaseline
							useDeliverables
							budgetType
							budget
							id
							status
							companyProjectId
							customProjectId
							projectColor
							name
							unassignedTaskHourlyCost
							taskCostCalculationType
							estimationUnit
							synchBaselineAndScopingDates
							useFixedPriceForBaselineRevenue
							baselineWinChance
							baselineAdjustPrice
							baselineAdjustPercentage
							baselineTarget
							baselineEstimationMinutesPerDay
							baselineTargetPrice
							baselineTargetMinutes
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
							salesforceOpportunity
							rateCard {
								id
								currency
								disabledRoles {
									id
								}
								rates {
									edges {
										node {
											rate
											defaultRate
											role {
												id
											}
										}
									}
								}
							}
							client {
								name
								street
								city
								zip
								vat
								logoId
							}
							rateCard {
								id
								currency
								disabledRoles {
									id
								}
							}
							financialNumbers(convertToProjectCurrency: true) {
								baselineMinutes
								baselineRevenue
								baselineTimeAndExpenses
								baselineCost
								baselineProfit
								baselineMargin
							}
							phases(first: 1000000) @connection(key: "Project_phases", filters: []) {
								edges {
									node {
										id
										name
										startYear
										startMonth
										startDay
										deadlineYear
										deadlineMonth
										deadlineDay
										sortOrder
										baselineStartYear
										baselineStartMonth
										baselineStartDay
										baselineDeadlineDay
										baselineDeadlineMonth
										baselineDeadlineYear
										averageRatesByRole @include(if: $hasDeliverablesFlag) {
											roleId
											costRate
											rate
										}
										projectId
										phaseBaselineRoles(first: 100000)
											@connection(key: "Phase_phaseBaselineRoles", filters: []) {
											edges {
												node {
													id
													phaseId
													baselineMinutes
													role {
														id
														name
													}
												}
											}
										}
										phaseBaselineExpenses(first: 100000)
											@connection(key: "Phase_phaseBaselineExpenses", filters: []) {
											edges {
												node {
													id
													phaseId
													expenseMarkup
													expenseCategory {
														id
														name
													}
												}
											}
										}
										phaseDeliverables(first: 10000)
											@connection(key: "Phase_phaseDeliverables", filters: []) {
											edges {
												node {
													id
													phaseId
													deliverable {
														id
														name
													}
													financialNumbers(convertToProjectCurrency: true) {
														baselineMinutes
														baselineTimeAndExpenses
														baselineRevenue
														baselineCost
														baselineProfit
														baselineMargin
													}
												}
											}
										}
									}
									...PhasesSection_phases
								}
							}
							phaseBaselineExpenses(first: 1000000)
								@connection(key: "Project_phaseBaselineExpenses", filters: []) {
								edges {
									node {
										id
										expenseName
										expenseCategory {
											name
											id
										}
										expenseRevenue
										expenseCost
										expenseMarkup
										expenseProfit
										globalPhaseId
										deliverableId
									}
								}
							}
							phaseBaselineRoles(first: 1000000) @connection(key: "Project_phaseBaselineRoles", filters: []) {
								edges {
									node {
										id
										globalPhaseId
										deliverableId
										role {
											name
											id
										}
										financialNumbers(convertToProjectCurrency: true) {
											baselineMinutes
											baselineTimeAndExpenses
											baselineRevenue
											baselineCost
											baselineProfit
											baselineRatePerHour
											baselineCostPerHour
										}
									}
								}
							}
							deliverables(first: 1000000) @connection(key: "Project_deliverables", filters: []) {
								edges {
									node {
										id
										name
										description
										projectId
									}
								}
							}
							expenseItems(first: 1000000) @connection(key: "Project_expenseItems", filters: []) {
								edges {
									node {
										id
									}
								}
							}
							timeRegistrations(first: 1000000) @connection(key: "Project_timeRegistrations", filters: []) {
								edges {
									node {
										id
									}
								}
							}
							invoices(first: 100000000) {
								edges {
									node {
										id
										status
									}
								}
							}
							groupedFinancialNumbers(
								convertToProjectCurrency: true
								groupBy: ["PHASE_BASELINE_ROLE", "PHASE_BASELINE_EXPENSE", "PHASE"]
							) {
								baselineMinutes
								baselineRevenue
								baselineTimeAndExpenses
								baselineCost
								baselineProfit
								baselineMargin
								baselineRatePerHour
								baselineCostPerHour
								phaseBaselineRoleId
								phaseBaselineExpenseId
								phaseId
							}
						}
						psProject(companyProjectId: $projectId) {
							...ProjectHeader_psProject
						}
					}
				`,
			})
		)
	)
);
