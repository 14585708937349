import React, { useMemo } from 'react';
import { NestedCard, FlexRow, Text, SubHeading, FlexColumn, Icon, Frame, useTableFunctions } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
import { useState, useEffect } from 'react';
import { idFromGlobalId } from '../../forecast-app/shared/util/GlobalIdUtil';
import { getTotals } from './BaselineEditPage';
import { uniqueId } from 'lodash';
import { TimeEstimatesTable } from './TimeEstimatesTable';
import { ExpensesEstimatesTable } from './ExpensesEstimateTable';
import styled from 'styled-components';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
const SummaryCard = (props) => (React.createElement(FlexColumn, null,
    React.createElement(Text, { color: "auxiliary", size: '2' }, props.label),
    React.createElement(SubHeading, { size: '2' }, props.value)));
const SummaryContainer = styled(FlexRow) `
	padding: 8px;
`;
const renormaliseRoleRevenues = (updatedRows, multiplier) => updatedRows.map(row => {
    if ('phaseBaselineRoleId' in row) {
        const revenue = row.valueOfService * multiplier;
        const profit = revenue - row.cost;
        const margin = revenue ? Util.roundFloatToTwoDecimals(profit / revenue) : 0;
        return Object.assign(Object.assign({}, row), { revenue, profit, margin });
    }
    return row;
});
const AddButtonWrapper = styled.div `
	padding-top: 8px;
	width: fit-content;
`;
const TableTitleWrapper = styled(FlexRow) `
	margin-bottom: 8px;
`;
const AddButton = (props) => (React.createElement(AddButtonWrapper, { onClick: props.onClick, style: { cursor: 'pointer' } },
    React.createElement(FlexRow, null,
        React.createElement(Icon, { icon: "plus", size: "m", color: "primary" }),
        React.createElement(SubHeading, { color: 'primary' }, props.text))));
export const DeliverableCard = ({ phaseDeliverable, currency, eyeOptionMap, editMode, company, phase, setProjectBaselineItems, baselineItems, groupedProjectTotals, projectBudget, isFixedPrice, projectDeliverables, setPhaseDeliverables, }) => {
    var _a;
    const { formatMessage, formatNumber } = useIntl();
    const { financialNumbers, deliverable } = phaseDeliverable || {};
    const [rolesTableRef] = useTableFunctions();
    const [expensesTableRef] = useTableFunctions();
    const [phaseDeliverableBaselineTotals, setPhaseDeliverableBaselineTotals] = useState(financialNumbers);
    const { baselineCost, baselineMargin, baselineMinutes, baselineProfit, baselineRevenue, baselineTimeAndExpenses } = phaseDeliverableBaselineTotals || {};
    useEffect(() => {
        const updatedTotals = getTotals(baselineItems || []);
        setPhaseDeliverableBaselineTotals(updatedTotals);
    }, [baselineItems]);
    const formatCurrency = (value) => formatNumber(value, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' });
    const [roundedHours, roundedvalueOfService, roundedRevenue, roundedCost, roundedprofit] = useMemo(() => [
        (baselineMinutes || 0) / 60,
        baselineTimeAndExpenses || 0,
        baselineRevenue || 0,
        baselineCost || 0,
        baselineProfit || 0,
    ].map(Util.roundFloatToTwoDecimals), [phaseDeliverableBaselineTotals]);
    const roundedMargin = Math.trunc((baselineMargin || 0) * 100);
    const hours = `${roundedHours}h`;
    const valueOfService = formatCurrency(roundedvalueOfService);
    const revenue = formatCurrency(roundedRevenue);
    const cost = formatCurrency(roundedCost);
    const profit = formatCurrency(roundedprofit);
    const margin = `${roundedMargin.toString()}%`;
    /* roles */
    const emptyRole = () => {
        var _a;
        return ({
            phaseBaselineRoleId: uniqueId(),
            projectId: (phase === null || phase === void 0 ? void 0 : phase.projectId) || '',
            phaseId: (phase === null || phase === void 0 ? void 0 : phase.id) || '',
            deliverableId: ((_a = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id) || '',
            roleName: '',
            roleId: '',
            hours: 0,
            valueOfService: 0,
            revenue: 0,
            cost: 0,
            profit: 0,
            ratePerHour: 0,
            costPerHour: 0,
        });
    };
    const rolesData = baselineItems === null || baselineItems === void 0 ? void 0 : baselineItems.filter(item => item && 'phaseBaselineRoleId' in item);
    const timeEstimatesSubHeading = `${formatMessage({ id: 'deliverables.edit_baseline.time_estimates' })} (${(rolesData === null || rolesData === void 0 ? void 0 : rolesData.length) || 0})`;
    const hasTimeTable = (rolesData === null || rolesData === void 0 ? void 0 : rolesData.length) > 0;
    const recalculateRoleRows = (row) => {
        const { hours, costPerHour, ratePerHour } = row;
        const valueOfService = ratePerHour * hours;
        const cost = costPerHour * hours;
        const revenue = valueOfService;
        const profit = revenue - cost;
        const updateFields = { valueOfService, cost, profit, revenue };
        const newRow = Object.assign(Object.assign({}, row), updateFields);
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                const rowIndex = old.findIndex(item => 'phaseBaselineRoleId' in item && item.phaseBaselineRoleId === row.phaseBaselineRoleId);
                const updatedRows = [...old.slice(0, rowIndex), newRow, ...old.slice(rowIndex + 1)];
                if (projectBudget !== undefined && isFixedPrice) {
                    const oldRow = old[rowIndex];
                    const valueOfServiceDiff = valueOfService - oldRow.valueOfService;
                    const newTotalRolesValueOfService = ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses) || 0) + valueOfServiceDiff;
                    const multiplier = (projectBudget - ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.expensesTotals.baselineRevenue) || 0)) /
                        newTotalRolesValueOfService;
                    return renormaliseRoleRevenues(updatedRows, multiplier);
                }
                return updatedRows;
            });
        }
    };
    const onRoleSelect = (roleId, row) => {
        var _a, _b;
        const convertedRoleId = idFromGlobalId(roleId);
        const averageRate = phase && phase.averageRatesByRole ? phase === null || phase === void 0 ? void 0 : phase.averageRatesByRole.find(rate => (rate === null || rate === void 0 ? void 0 : rate.roleId) === convertedRoleId) : null;
        const role = (_b = (_a = company === null || company === void 0 ? void 0 : company.roles) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(edge => { var _a; return ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id) === roleId; });
        if (role && role.node) {
            const newRow = row;
            newRow.roleId = role.node.id;
            newRow.roleName = role.node.name || '';
            newRow.ratePerHour = averageRate && averageRate.rate ? averageRate.rate : 0;
            newRow.costPerHour = averageRate && averageRate.costRate ? averageRate.costRate : 0;
            recalculateRoleRows(newRow);
        }
    };
    const onRoleAdd = () => {
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                return [...old, emptyRole()];
            });
        }
    };
    const onDeleteRoleRow = (row) => {
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                const rowIndex = old.findIndex(item => 'phaseBaselineRoleId' in item && item.phaseBaselineRoleId === row.phaseBaselineRoleId);
                const updatedRows = [...old.slice(0, rowIndex), ...old.slice(rowIndex + 1)];
                if (projectBudget !== undefined && isFixedPrice) {
                    const oldRow = old[rowIndex];
                    const totalRolesValueOfService = ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses) || 0) - oldRow.valueOfService;
                    const multiplier = totalRolesValueOfService
                        ? (projectBudget - ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.expensesTotals.baselineRevenue) || 0)) /
                            totalRolesValueOfService
                        : 1;
                    return renormaliseRoleRevenues(updatedRows, multiplier);
                }
                return updatedRows;
            });
        }
    };
    /* expenses */
    const emptyExpense = () => {
        var _a;
        return ({
            phaseBaselineExpenseId: uniqueId(),
            projectId: (phase === null || phase === void 0 ? void 0 : phase.projectId) || '',
            phaseId: (phase === null || phase === void 0 ? void 0 : phase.id) || '',
            deliverableId: ((_a = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id) || '',
            name: '',
            categoryName: '',
            categoryId: '',
            revenue: 0,
            cost: 0,
            markup: 0,
            profit: 0,
            margin: 0,
        });
    };
    const expensesData = baselineItems === null || baselineItems === void 0 ? void 0 : baselineItems.filter(item => item && 'phaseBaselineExpenseId' in item);
    const expenseEstimatesSubHeading = `${formatMessage({ id: 'deliverables.edit_baseline.expense_estimates' })} (${(expensesData === null || expensesData === void 0 ? void 0 : expensesData.length) || 0})`;
    const hasExpensesTable = (expensesData === null || expensesData === void 0 ? void 0 : expensesData.length) > 0;
    const recalculateExpenseRows = (row, changedField) => {
        let revenue, cost, markup;
        if (changedField === 'revenue' || changedField === 'cost') {
            revenue = row.revenue;
            cost = row.cost;
            markup = cost ? (revenue - cost) / cost : 0;
        }
        else {
            cost = row.cost;
            markup = row.markup;
            revenue = cost + markup * cost;
        }
        const profit = revenue - cost;
        const margin = revenue ? profit / revenue : 0;
        const updateFields = { profit, margin, markup, revenue, cost };
        const newRow = Object.assign(Object.assign({}, row), updateFields);
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                const rowIndex = old.findIndex(item => 'phaseBaselineExpenseId' in item && item.phaseBaselineExpenseId === row.phaseBaselineExpenseId);
                const updatedRows = [...old.slice(0, rowIndex), newRow, ...old.slice(rowIndex + 1)];
                if (projectBudget !== undefined && isFixedPrice) {
                    const oldRow = old[rowIndex];
                    const revenueDiff = revenue - oldRow.revenue;
                    const newTotalExpensesRevenue = ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.expensesTotals.baselineRevenue) || 0) + revenueDiff;
                    const multiplier = (groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses)
                        ? (projectBudget - newTotalExpensesRevenue) / (groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses)
                        : 1;
                    return renormaliseRoleRevenues(updatedRows, multiplier);
                }
                return updatedRows;
            });
        }
    };
    const onExpenseCategorySelect = (categoryId, row) => {
        var _a, _b;
        const category = (_b = (_a = company === null || company === void 0 ? void 0 : company.expenseCategories) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(edge => { var _a; return ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.id) === categoryId; });
        if (category && category.node && category.node.name) {
            const newRow = Object.assign(Object.assign({}, row), { categoryId, categoryName: category.node.name });
            if (setProjectBaselineItems) {
                setProjectBaselineItems(old => {
                    const rowIndex = old.findIndex(item => 'phaseBaselineExpenseId' in item && item.phaseBaselineExpenseId === row.phaseBaselineExpenseId);
                    return [...old.slice(0, rowIndex), newRow, ...old.slice(rowIndex + 1)];
                });
            }
        }
    };
    const onExpenseAdd = () => {
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                return [...old, emptyExpense()];
            });
        }
    };
    const onDeleteExpenseRow = (row) => {
        if (setProjectBaselineItems) {
            setProjectBaselineItems(old => {
                const rowIndex = old.findIndex(item => 'phaseBaselineExpenseId' in item && item.phaseBaselineExpenseId === row.phaseBaselineExpenseId);
                const updatedRows = [...old.slice(0, rowIndex), ...old.slice(rowIndex + 1)];
                if (projectBudget !== undefined && isFixedPrice) {
                    const oldRow = old[rowIndex];
                    const newTotalExpensesRevenue = ((groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.expensesTotals.baselineRevenue) || 0) - oldRow.revenue;
                    const multiplier = (groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses)
                        ? (projectBudget - newTotalExpensesRevenue) / (groupedProjectTotals === null || groupedProjectTotals === void 0 ? void 0 : groupedProjectTotals.rolesTotals.baselineTimeAndExpenses)
                        : 1;
                    return renormaliseRoleRevenues(updatedRows, multiplier);
                }
                return updatedRows;
            });
        }
    };
    const handleChangeDeliverable = (newDeliverable) => {
        setPhaseDeliverables &&
            setPhaseDeliverables(old => {
                const prevIndex = old.findIndex(item => item.id === (phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.id));
                const phaseDeliverableAlreadyExists = old.find(phaseDeliverable => { var _a; return ((_a = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id) === newDeliverable.id && (phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.phaseId) === (phase === null || phase === void 0 ? void 0 : phase.id); });
                if (phaseDeliverableAlreadyExists) {
                    return [...old.slice(0, prevIndex), ...old.slice(prevIndex + 1)];
                }
                const updatedPhaseDeliverable = Object.assign(Object.assign({}, old[prevIndex]), { deliverable: newDeliverable });
                return [...old.slice(0, prevIndex), updatedPhaseDeliverable, ...old.slice(prevIndex + 1)];
            });
        setProjectBaselineItems &&
            setProjectBaselineItems(old => {
                return old.map(baselineItem => baselineItem.deliverableId === (deliverable === null || deliverable === void 0 ? void 0 : deliverable.id) && baselineItem.phaseId === (phase === null || phase === void 0 ? void 0 : phase.id)
                    ? Object.assign(Object.assign({}, baselineItem), { deliverableId: newDeliverable.id }) : baselineItem);
            });
    };
    const showChangeDeliverableModal = () => {
        showModal({
            type: MODAL_TYPE.CHANGE_DELIVERABLE_MODAL,
            projectDeliverables,
            currentDeliverable: deliverable,
            callback: handleChangeDeliverable,
        });
    };
    const handleDeleteDeliverable = () => {
        setPhaseDeliverables &&
            setPhaseDeliverables(old => {
                const prevIndex = old.findIndex(item => item.id === (phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.id));
                return [...old.slice(0, prevIndex), ...old.slice(prevIndex + 1)];
            });
        setProjectBaselineItems &&
            setProjectBaselineItems(old => {
                return old.filter(baselineItem => baselineItem.deliverableId !== (deliverable === null || deliverable === void 0 ? void 0 : deliverable.id) && baselineItem.phaseId !== (phase === null || phase === void 0 ? void 0 : phase.id));
            });
    };
    const showDeleteDeliverableModal = () => {
        showModal({
            type: MODAL_TYPE.REMOVE_DELIVERABLE_FROM_PHASE_MODAL,
            callback: handleDeleteDeliverable,
        });
    };
    const showManageDeliverablesModal = () => {
        showModal({
            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
            deliverables: projectDeliverables,
            projectId: phase === null || phase === void 0 ? void 0 : phase.projectId,
        });
    };
    const contextMenuOptions = editMode
        ? [
            {
                name: formatMessage({ id: 'deliverable.change_deliverable' }),
                onClick: showChangeDeliverableModal,
            },
            {
                name: formatMessage({ id: 'deliverable.remove_deliverable_from_phase' }),
                onClick: showDeleteDeliverableModal,
            },
        ]
        : [
            {
                name: formatMessage({ id: 'deliverables.manage.modal.manage_deliverables' }),
                onClick: showManageDeliverablesModal,
            },
        ];
    return (React.createElement(NestedCard, { colorTheme: "medium", initiallyExpanded: !!((_a = phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.cardState) === null || _a === void 0 ? void 0 : _a.expanded), dataCy: 'deliverable-nested-card' },
        React.createElement(NestedCard.Header, Object.assign({}, (deliverable
            ? {
                mainIcon: 'deliverable',
                sectionName: formatMessage({ id: 'common.deliverable' }),
            }
            : { sectionName: formatMessage({ id: 'deliverable.no_deliverable' }) }), { name: (deliverable === null || deliverable === void 0 ? void 0 : deliverable.name) || '', showContextMenu: true, contextMenuOptions: contextMenuOptions })),
        !!(baselineItems === null || baselineItems === void 0 ? void 0 : baselineItems.length) && (React.createElement(NestedCard.SummaryContent, null,
            React.createElement(SummaryContainer, { justifyContent: "center" },
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.hours' }), value: hours }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.value_of_service' }), value: valueOfService }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'project_budget.revenue' }), value: revenue }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.cost' }), value: cost }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.profit' }), value: profit }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.margin' }), value: margin })))),
        React.createElement(NestedCard.CollapsibleContentWrapper, null,
            React.createElement(NestedCard.Content, null,
                React.createElement(FlexColumn, { gap: "m" },
                    React.createElement("div", { style: { marginBottom: '16px' } },
                        React.createElement(TableTitleWrapper, null,
                            React.createElement(Icon, { icon: "deliverablesTimeEstimate", size: "l" }),
                            React.createElement(SubHeading, { size: '2' }, timeEstimatesSubHeading)),
                        hasTimeTable ? (React.createElement(TimeEstimatesTable, { onDeleteRow: onDeleteRoleRow, onRoleSelect: onRoleSelect, recalculate: recalculateRoleRows, editMode: editMode, company: company, ref: rolesTableRef, tableData: rolesData, eyeOptionMap: eyeOptionMap, setTableData: setProjectBaselineItems })) : (React.createElement(Frame, { centerContent: true },
                            React.createElement(Text, { color: "auxiliary" }, formatMessage({ id: 'deliverable.no_time_estimates' })))),
                        editMode && (React.createElement(AddButton, { text: formatMessage({ id: 'deliverable.add_hours_estimate' }), onClick: onRoleAdd }))),
                    React.createElement("div", null,
                        React.createElement(TableTitleWrapper, null,
                            React.createElement(Icon, { icon: "expensesNew", size: "l" }),
                            React.createElement(SubHeading, { size: '2' }, expenseEstimatesSubHeading)),
                        hasExpensesTable ? (React.createElement(ExpensesEstimatesTable, { onDeleteRow: onDeleteExpenseRow, onCategorySelect: onExpenseCategorySelect, recalculateRow: recalculateExpenseRows, editMode: editMode, company: company, ref: expensesTableRef, tableData: expensesData, eyeOptionMap: eyeOptionMap, setTableData: setProjectBaselineItems })) : (React.createElement(Frame, { centerContent: true },
                            React.createElement(Text, { color: "auxiliary" }, formatMessage({ id: 'deliverable.no_expense_estimates' })))),
                        editMode && (React.createElement(AddButton, { text: formatMessage({ id: 'deliverable.add_expenses_estimate' }), onClick: onExpenseAdd }))))))));
};
