import React, { useState, useCallback, useEffect } from 'react';
import { FlexColumn, FlexRow, Modal, TextField } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import RichTextItem from '../richtext_item';
import CreateDeliverableMutation from '../../mutations/deliverables/CreateDeliverableMutation';
import DeleteDeliverableMutation from '../../mutations/deliverables/DeleteDeliverableMutation';
import UpdateDeliverableMutation from '../../mutations/deliverables/UpdateDeliverableMutation';
import { CommitMutation } from '../../mutations/ts/CommitMutation';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../forecast-app/shared/util/util';
const DeliverableEditorModal = ({ closeModal, deliverables, deliverable, action, projectId, onSuccess, }) => {
    const intl = useIntl();
    const [description, setDescription] = useState((deliverable === null || deliverable === void 0 ? void 0 : deliverable.description) || '');
    const [name, setName] = useState((deliverable === null || deliverable === void 0 ? void 0 : deliverable.name) || '');
    const [closeEditor, setCloseEditor] = useState(false);
    const isCreate = action === 'create';
    const modalTitle = isCreate
        ? intl.formatMessage({ id: 'deliverables.edit.modal.add_new_deliverable' })
        : intl.formatMessage({ id: 'deliverables.edit.modal.edit_deliverable' });
    const buttonText = isCreate
        ? intl.formatMessage({ id: 'deliverables.edit.modal.add_deliverable' })
        : intl.formatMessage({ id: 'deliverables.edit.modal.save_changes' });
    const onCreateSuccess = response => {
        var _a, _b;
        closeModal();
        const deliverable = (_b = (_a = response === null || response === void 0 ? void 0 : response.createDeliverable) === null || _a === void 0 ? void 0 : _a.deliverable) === null || _b === void 0 ? void 0 : _b.node;
        if (onSuccess && deliverable) {
            return onSuccess(deliverable);
        }
        showModal({
            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
            deliverables: [...deliverables, { node: deliverable }],
            projectId,
        });
    };
    const onUpdateSuccess = response => {
        var _a, _b;
        closeModal();
        const updatedDeliverable = (_b = (_a = response === null || response === void 0 ? void 0 : response.updateDeliverable) === null || _a === void 0 ? void 0 : _a.deliverable) === null || _b === void 0 ? void 0 : _b.node;
        const updatedDeliverables = deliverables.map(d => d.node.id === updatedDeliverable.id ? { node: updatedDeliverable } : d);
        showModal({
            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
            deliverables: updatedDeliverables,
            projectId,
        });
    };
    const onDeleteSuccess = response => {
        var _a;
        setCloseEditor(true);
        closeModal();
        const deletedDeliverableId = (_a = response === null || response === void 0 ? void 0 : response.deleteDeliverable) === null || _a === void 0 ? void 0 : _a.deletedDeliverableId;
        showModal({
            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
            deliverables: deliverables.filter(deliverable => deliverable.node.id !== deletedDeliverableId),
            projectId,
        });
    };
    const handleCreate = useCallback(() => {
        CommitMutation(CreateDeliverableMutation, { name, description, projectId }, onCreateSuccess);
    }, [name, description, deliverables, projectId, closeModal]);
    const handleSave = useCallback(() => {
        CommitMutation(UpdateDeliverableMutation, { id: String(deliverable === null || deliverable === void 0 ? void 0 : deliverable.id), name, description, projectId }, onUpdateSuccess);
    }, [name, description, deliverables, projectId, closeModal]);
    const handleDelete = () => {
        Util.CommitMutation(DeleteDeliverableMutation, { id: deliverable === null || deliverable === void 0 ? void 0 : deliverable.id, projectId }, onDeleteSuccess);
    };
    const deleteConfirmation = () => {
        showModal({
            type: MODAL_TYPE.DELETE_DELIVERABLE_CONFIRMATION_MODAL,
            handleDelete,
        });
    };
    useEffect(() => {
        if (closeEditor) {
            closeModal();
        }
    }, [closeEditor]);
    return (React.createElement(Modal, { size: Modal.SIZE.EXTRA_LARGE, onCloseModal: closeModal },
        React.createElement(Modal.Title, null, modalTitle),
        React.createElement(Modal.Content, null,
            React.createElement(TextField, { label: intl.formatMessage({ id: 'deliverables.manage.modal.deliverable_name' }), value: name, onValueChange: setName, size: "large", width: "flex" }),
            React.createElement(FlexColumn, null,
                React.createElement(FlexRow, null, intl.formatMessage({ id: 'deliverables.edit.modal.description_optional' })),
                React.createElement(RichTextItem, { cy: "deliverable-description", alwaysShowControls: true, text: description, handleTextChange: setDescription, hideEmojiPicker: true }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { disabled: name === '', onPress: isCreate ? handleCreate : handleSave, dataCy: "action" },
                React.createElement(FormattedMessage, { id: buttonText })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, dataCy: "cancel" },
                React.createElement(FormattedMessage, { id: "common.cancel" })),
            !isCreate && (React.createElement(Modal.TertiaryFooterButton, { onPress: deleteConfirmation },
                intl.formatMessage({ id: 'deliverables.edit.modal.delete_deliverable' }),
                ' ')))));
};
export default DeliverableEditorModal;
