import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { DropdownList, FlexRow, tokensForThemeProvider, EMPHASIS, Icon, SubHeading, Button, Text, ButtonDropdownField, useExpandable, } from '@forecast-it/design-system';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import { uniqueId } from 'lodash';
import { useIntl } from 'react-intl';
const Card = styled(FlexRow) `
	background-color: ${tokensForThemeProvider.sky.ref.color.blue[200]};
	border-radius: ${tokensForThemeProvider.sky.sys.spacing[100]};
	height: 56px;
	padding-left: 8px;
`;
const ButtonContainer = styled.div `
	cursor: pointer;
`;
const StyledButtonContainer = styled.div `
	cursor: pointer;
	padding: 16px;
	border-top: 1px solid ${tokensForThemeProvider.sky.sys.color.border.decorative};
`;
const AddDeliverableButton = (props) => (React.createElement(ButtonContainer, { onClick: props.onClick },
    React.createElement(FlexRow, null,
        React.createElement(Icon, { icon: "deliverable", size: "m", color: "primary" }),
        React.createElement(SubHeading, { color: 'primary' }, props.text))));
const AddDeliverableDropdownItem = (props) => (React.createElement(StyledButtonContainer, { onClick: props.onClick },
    React.createElement(FlexRow, { justifyContent: "" },
        React.createElement(SubHeading, null, props.text),
        React.createElement(Icon, { icon: "plus", size: "m" }))));
export const SelectDeliverableCard = (props) => {
    const { setProjectPhaseDeliverables, deliverablesInPhase, project, text } = props;
    const { formatMessage } = useIntl();
    const [editing, setEditing] = useState(false);
    const [selectedDeliverable, setSelectedDeliverable] = useState(null);
    const { expand } = useExpandable();
    const usedDeliverableSet = useMemo(() => new Set(deliverablesInPhase.map(phaseDeliverable => { var _a; return (_a = phaseDeliverable.deliverable) === null || _a === void 0 ? void 0 : _a.id; }).filter(Boolean)), [deliverablesInPhase]);
    const deliverables = useMemo(() => props.deliverables.filter(deliverable => { var _a, _b; return ((_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.id) && !usedDeliverableSet.has((_b = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _b === void 0 ? void 0 : _b.id); }), [usedDeliverableSet]);
    const addPhaseDeliverable = (deliverable) => {
        (deliverable === null || deliverable === void 0 ? void 0 : deliverable.id) &&
            setProjectPhaseDeliverables &&
            setProjectPhaseDeliverables(previous => usedDeliverableSet.has(deliverable === null || deliverable === void 0 ? void 0 : deliverable.id)
                ? previous
                : [
                    ...previous,
                    {
                        id: uniqueId(),
                        phaseId: props.phaseId,
                        deliverable: {
                            id: deliverable.id,
                            name: deliverable.name,
                        },
                        financialNumbers: {
                            baselineMinutes: 0,
                            baselineTimeAndExpenses: 0,
                            baselineRevenue: 0,
                            baselineCost: 0,
                            baselineProfit: 0,
                            baselineMargin: 0,
                        },
                        cardState: {
                            expanded: true,
                        },
                    },
                ]);
        setSelectedDeliverable(null);
        expand();
    };
    const openDeliverableCreationModal = () => {
        showModal({
            type: MODAL_TYPE.DELIVERABLE_EDITOR_MODAL,
            deliverables: props.deliverables,
            deliverable: null,
            action: 'create',
            projectId: project === null || project === void 0 ? void 0 : project.id,
            onSuccess: addPhaseDeliverable,
        });
    };
    return (React.createElement(Card, null, editing ? (React.createElement("div", { style: { width: '50%' } },
        React.createElement(FlexRow, { gap: 's' },
            React.createElement(Icon, { icon: "deliverable", size: "m" }),
            React.createElement(Text, null, formatMessage({ id: 'common.deliverable' })),
            React.createElement(DropdownList, null,
                React.createElement(DropdownList.Trigger, null,
                    React.createElement(ButtonDropdownField, null, selectedDeliverable ? (React.createElement(Text, { ellipsis: true, size: '1' }, selectedDeliverable.name)) : (React.createElement(Text, { ellipsis: true, size: '1', color: "auxiliary" }, formatMessage({ id: 'deliverable.select_a_deliverable' }))))),
                React.createElement(DropdownList.Content, { offset: 10, useTriggerWidth: true },
                    deliverables.map(deliverable => {
                        var _a;
                        return (React.createElement(DropdownList.Item, { onClick: () => setSelectedDeliverable((deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) || null) }, (_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.name));
                    }),
                    React.createElement(AddDeliverableDropdownItem, { onClick: openDeliverableCreationModal, text: formatMessage({ id: 'deliverable.add_new_deliverable' }) }))),
            React.createElement(Button, { emphasis: EMPHASIS.HIGH, disabled: !selectedDeliverable, onClick: () => addPhaseDeliverable(selectedDeliverable) }, formatMessage({ id: 'common.select' })),
            React.createElement(Button, { emphasis: EMPHASIS.MEDIUM, onClick: () => setEditing(false) }, formatMessage({ id: 'common.cancel' }))))) : (React.createElement(FlexRow, null,
        React.createElement(AddDeliverableButton, { text: text, onClick: () => setEditing(true) })))));
};
