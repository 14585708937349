import React, { useMemo, useState } from 'react';
import { ButtonDropdownField, DropdownList, Modal, SubHeading, Text } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
const ChangeDeliverableModal = ({ closeModal, callback, projectDeliverables, currentDeliverable, }) => {
    const { formatMessage } = useIntl();
    const [selectedDeliverable, setSelectedDeliverable] = useState(null);
    const onClickConfirm = () => {
        if (!selectedDeliverable) {
            return;
        }
        callback(selectedDeliverable);
        closeModal();
    };
    const unSelectedDeliverables = useMemo(() => projectDeliverables.filter(deliverable => { var _a; return ![selectedDeliverable === null || selectedDeliverable === void 0 ? void 0 : selectedDeliverable.id, currentDeliverable.id].includes((_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.id); }), [projectDeliverables, selectedDeliverable, currentDeliverable]);
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'deliverable.change_deliverable' })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedHTMLMessage, { id: 'deliverable.change_deliverable.description' }),
            React.createElement(SubHeading, null, formatMessage({ id: 'deliverable.change_deliverable.current_selected' })),
            React.createElement(Text, null, currentDeliverable.name),
            React.createElement(SubHeading, null, formatMessage({ id: 'deliverable.select_another_deliverable' })),
            React.createElement(DropdownList, null,
                React.createElement(DropdownList.Trigger, null,
                    React.createElement(ButtonDropdownField, null, !!selectedDeliverable && (React.createElement(Text, { ellipsis: true, size: '1' }, selectedDeliverable.name)))),
                !!unSelectedDeliverables.length && (React.createElement(DropdownList.Content, { offset: 10, useTriggerWidth: true }, unSelectedDeliverables.map(deliverable => {
                    var _a;
                    return (React.createElement(DropdownList.Item, { onClick: () => setSelectedDeliverable((deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) || null) }, (_a = deliverable === null || deliverable === void 0 ? void 0 : deliverable.node) === null || _a === void 0 ? void 0 : _a.name));
                }))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onClickConfirm, dataCy: 'modal-btn-confirm', disabled: !selectedDeliverable },
                React.createElement(FormattedMessage, { id: "common.confirm" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: "common.go_back" })))));
};
export default ChangeDeliverableModal;
